import React, { useState } from 'react'
import { IoMdCreate } from 'react-icons/io'
import Modal from 'react-responsive-modal'
import { Form, Row, Col, Table } from 'react-bootstrap';
import styles from '../styles.module.scss'
import { useFormik } from 'formik';
import { getOptions, getYearsOptions } from '../../../utils/getOptions';
import ReactQuill from 'react-quill';
import FileLabel from '../../../components/FileLabel/FileLabel';
import { AddFileModal } from '../../../components/AddFileModal';
import AwardsBlock from '../../../components/AwardsBlock';
import moment from 'moment';
import { BACKEND_URL_PREFIX, categories } from '../../../assets/const';
import Button from '../../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { updateRequest } from '../../../actions/teacher';



const RequestEdit = ({ data, specializations }) => {
  
  const dispatch = useDispatch()
  const { loading } = useSelector(state => state.teacher)


  const [modalOpen, setModalOpen] = useState(false)
  const [addedFiles, setAddedFiles] = useState({cert_request_scan: [], cert_request_common: []})

  const editRequestInfoHandler = async (values) => {
    const {id, cert_year, position_id, category, cert_reason, current_position, edu_exp, position_exp, org_exp, request_files_json, status} = values
    const payload = {cert_year, position_id, category, cert_reason, current_position, edu_exp, position_exp, org_exp, request_files_json, status}
    
    await dispatch(updateRequest(id, payload, addedFiles))
    setModalOpen(false)
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setTouched,
    isValid
  } = useFormik({
    initialValues: data,
    onSubmit: editRequestInfoHandler
  })

  const setModal = (state) => () => setModalOpen(state)
  
  const renderPedQualRow = ({name, year, hours}) => (
    <tr>
      <td>{name}</td>
      <td>{moment(year).year()}</td>
      <td style={{textAlign: 'center'}}>{hours}</td>
    </tr>
  )

  const renderExistFile = (entityType) => (acc, file, index) => {
    if (entityType === file.entity_type) return acc;
    const fileLink = `${BACKEND_URL_PREFIX || ''}/${file.filepath}/${file.filefilename}`
    const fileObj = (
      <FileLabel
        fileLink={fileLink}
        fileName={file.user_filename}
        imageMode={file.filename.includes('image')}
        pdfMode={file.filename.includes('pdf')}
        onDelete={onDeleteExistFiles(index)}
      />
    )
    return [...acc, fileObj] 
  }

  const onDeleteExistFiles = (index) => () => {
    const newList = values.request_files_json.filter((f, ind) => ind !== index)
    setFieldValue('request_files_json', newList)
  }
  
  const onAddFile = (fileObject) => {
    const { entity_type: name, file } = fileObject
    const _file = file[0]
    const newItem = {...fileObject, file: _file}
    const prevValue = addedFiles[name] || []
    const filesList = [...prevValue, newItem]
    setAddedFiles(prev => ({...prev, [name]: filesList}))
  }
  
  const onDelete = (name, index) => () => {
    const filesList = [...addedFiles[name]]
    filesList.splice(index, 1)
    setAddedFiles(prev => ({...prev, [name]: filesList}))
  }

  const renderNewFiles = (file, index) => {
    return (
      <FileLabel
        fileLink={URL.createObjectURL(file.file)}
        fileName={file.user_filename}
        imageMode={file.file.type.includes('image')}
        pdfMode={file.file.type.includes('pdf')}
        onDelete={onDelete('cert_request_common', index)}
      />
    )
  }

  return (
    <>
      <IoMdCreate onClick={setModal(true)}/>
      <Modal
        open={modalOpen}
        classNames={{modal: styles.infoModal}}
        showCloseIcon={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        animationDuration={1}
      >
        <Form onSubmit={handleSubmit}>
            
            <h2>Редактирование</h2>

            <Row className={styles.simpleCertRow}>
              <Form.Check
                label='аттестация педагогических работников, имеющих государственные награды, почетные звания, ведомственные знаки отличия, награды полученные за достижения в педагогической деятельности, награды конкурсов профессионального мастерства'
                checked={values.simple_cert}
                disabled
              />
            </Row>
            <Row className='mb-3'>
              
              <Form.Group as={Col} md={3}>
                <Form.Label className={styles.formLabels}>Год:</Form.Label>
                <Form.Select
                  name='cert_year'
                  value={values.cert_year}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.cert_year && errors.cert_year}
                  disabled
                  >
                  <option value=''>Выбрать</option>
                  {getYearsOptions(5)}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.cert_year}</Form.Control.Feedback>
              </Form.Group>
              
              <Form.Group as={Col} md={6}>
                <Form.Label className={styles.formLabels}>Должность:</Form.Label>
                <Form.Select
                  name='position_id'
                  value={values.position_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.position_id && errors.position_id}
                  disabled
                  >
                  <option value=''>Выбрать</option>
                  {getOptions(specializations)}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.position}</Form.Control.Feedback>
              </Form.Group>
              
              <Form.Group as={Col} md={3}>
                <Form.Label className={styles.formLabels}>Категория:</Form.Label>
                <Form.Select
                  name='category'
                  value={values.category}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.category && errors.category}
                  disabled
                >
                  <option value=''>Выбрать</option>
                  {getOptions(categories)}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.category}</Form.Control.Feedback>
              </Form.Group>

            </Row>
            <Row className='mb-3'>
              
              <Form.Group as={Col} md={7}>
                <Form.Label className={styles.formLabels}>Основание для аттестации:</Form.Label>
                <ReactQuill
                  className={styles.quill}
                  value={values.cert_reason}
                  name='cert_reason'
                  onChange={(val) => setFieldValue('cert_reason', val)}
                />
              </Form.Group>
              
              <Form.Group as={Col} md={5}>
                <Form.Label className={styles.formLabels}>Занимаемая должность:</Form.Label>
                <Form.Control
                  name='current_position'
                  value={values.current_position}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.current_position && errors.current_position}
                />
                <Form.Control.Feedback type="invalid">{errors.current_position}</Form.Control.Feedback>
              
              </Form.Group>
            </Row>
            <Row>
              
              <Form.Group className={styles.yearsInput} as={Col} md={4}>
                <Form.Label className={styles.formLabels}>Стаж в данной должности:</Form.Label>
                <Form.Control
                  name='position_exp'
                  value={values.position_exp}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.position_exp && errors.position_exp}
                  type='number'
                  />
                <Form.Control.Feedback type="invalid">{errors.position_exp}</Form.Control.Feedback>
              </Form.Group>
              
              <Form.Group className={styles.yearsInput} as={Col} md={4}>
                <Form.Label className={styles.formLabels}>Стаж педагогической работы:</Form.Label>
                <Form.Control
                  name='edu_exp'
                  value={values.edu_exp}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.edu_exp && errors.edu_exp}
                  type='number'
                  />
                <Form.Control.Feedback type="invalid">{errors.edu_exp}</Form.Control.Feedback>
              </Form.Group>
              
              <Form.Group className={styles.yearsInput} as={Col} md={4}>
                <Form.Label className={styles.formLabels}>Стаж в данном учреждении:</Form.Label>
                <Form.Control
                  name='org_exp'
                  value={values.org_exp}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.org_exp && errors.org_exp}
                  type='number'
                />
                <Form.Control.Feedback type="invalid">{errors.org_exp}</Form.Control.Feedback>
              </Form.Group>

            </Row>
            <p className={styles.yearsDescription}>полных лет на дату заполнения</p>
            
            <div className={styles.filesBlock}>
              <div className={styles.requestFiles}>
                <Form.Label className={styles.formLabels}>Скан копия заявления:</Form.Label>
                <div>
                  {values.request_files_json.reduce(renderExistFile('cert_request_scan'), [])}
                  {addedFiles.cert_request_scan.map(renderNewFiles)}
                  <AddFileModal
                    entity_type='cert_request_scan'
                    onAddFile={onAddFile}
                    />
                </div>
              </div>
              
              <div className={styles.teacherInfoFiles}>
                <Form.Label className={styles.formLabels}>Общие сведения о педагоге:</Form.Label>
                <div>
                  {values.request_files_json.reduce(renderExistFile('cert_request_common'), [])}
                  {addedFiles.cert_request_common.map(renderNewFiles)}
                  <AddFileModal
                    entity_type='cert_request_common'
                    onAddFile={onAddFile}
                  />
                </div>
              </div>
            </div>

            {/* {!!values.simple_cert &&
              <div>
              <Form.Label className={styles.formLabels}>Государственные награды, почетные звания, ведомственные знаки отличия, награды полученные за достижения в педагогической деятельности, награды конкурсов профессионального мастерства:</Form.Label>
              <AwardsBlock
                onAddFile={onAddFile}
                files={values.cert_request_award}
                onDeleteFile={onDelete}
                // disabled={criterions}
              />
            </div>} */}

            {/* <Row className={styles.moreInfoBlock}>
              <h5>Доп. информация:</h5>
              
              <Form.Group>
                <Form.Label className={styles.formLabels}>Ученая степень:</Form.Label>
                <div>{values.academic_degree || 'не указана'}</div>
              </Form.Group>
              
              <Form.Group>
                <Form.Label className={styles.formLabels}>Ученое звание:</Form.Label>
                <div>{values.academic_title || 'не указано'}</div>
              </Form.Group>
              
              <Form.Group>
                <Form.Label className={styles.formLabels}>Сведения о повышении квалификации:</Form.Label>
                <Table>
                  <thead>
                    <tr>
                      <th>Название</th>
                      <th>Год</th>
                      <th style={{width: '155px'}}>Количество часов</th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.ped_qual?.length
                      ? values.request_files_json.map(renderPedQualRow)
                      : <tr className={styles.noDataRow}>
                          <td colSpan={3}>Данные отсутствуют</td>
                        </tr>
                    }
                  </tbody>
                </Table>
              </Form.Group>

            </Row> */}

            <div className={styles.buttonsBlock}>
              <Button type='button' onClick={setModal(false)}>Отмена</Button>
              <Button type='submit' submitting={loading}>Сохранить</Button>
            </div>

          </Form>
      </Modal>
    </>
  )
}

export default RequestEdit