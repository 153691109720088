import React, {useRef, useState} from 'react'
import { FileDrop } from "react-file-drop";
import styles from './FileInput.module.css'
import cn from 'classnames'
import FileLabel from '../FileLabel/FileLabel'
import { BACKEND_DOMAIN } from '../../assets/const';
import { createNotification } from '../Notifications/Notifications';


export default function FileInput({
    onDelete,
    isInvalid,
    setTouched = () => {},
    height,
    width,
    files,
    disabled,
    name,
    onChange,
    ...props
}) {
    
    const inputRef = useRef();

    const filePicker = () => {
        inputRef.current.click();
    };

    const renderFile = (file, index) => {
      const existFile = file.file_id || file.id
      const fileName = existFile ? file.filename : file.name
      const imageMode = file.type?.includes('image') || file.mimetype?.includes('image')
      const fileLink = existFile ? `${BACKEND_DOMAIN||''}/${file.filepath}/${file.filename}` : URL.createObjectURL(file)
      return (
        <FileLabel
          key={index}
          fileName={fileName}
          imageMode={imageMode}
          fileLink={fileLink}
          onDelete={() => onDelete(name, index)}
        />
      )
    }

    const filesDrop = (files) => {
      setFileHaandler({target: {name, files}})
    }

    const setFileHaandler = (e) => {
      const { files } = e.target
      if (!files[0]) return;
      const { type } = files?.[0]
      const accept = ['pdf', 'mp4', 'wmv', 'mpeg', 'mp3'].find(i => type.includes(i))
      if (!accept) return createNotification('error', 'Недопустимый тип файла');
      onChange(e)
    }

    const fileWord = `ФАЙЛ${props.multiple ? 'Ы' : ''}`

    return (
    <div
      className={cn(styles.fileInputWrapper, {[styles.disabled]: disabled})}
      style={{height: height, width: width}}
      onMouseUp={() => setTouched(name)}
    >
        <div className={styles.filesList}>
            {files?.map(renderFile)}
        </div>
        <FileDrop
            className={cn(styles.inputField, {[styles.error]: isInvalid})}
            onTargetClick={filePicker}
            onDrop={filesDrop}
        >
            {isInvalid
              ? <p> {fileWord} <br/> ЯВЛЯЕТСЯ <br/> ОБЯЗАТЕЛЬНЫМ </p>
              : <p> ЗАГРУЗИТЬ <br/> {fileWord} </p>}
            <input
                accept=".pdf, video/mp4, video/x-ms-wmv, audio/mpeg, audio/mp3"
                ref={inputRef}
                type="file"
                name={name}
                onChange={setFileHaandler}
                hidden
                {...props}
            />
        </FileDrop>
    </div>
  )
}
