import React from 'react'
import Button from '../../../components/Button'
import { Form, Row, Col, Table } from 'react-bootstrap';
import styles from '../styles.module.css'
import { getOptions, getYearsOptions } from '../../../utils/getOptions'
import { BACKEND_DOMAIN, categories } from '../../../assets/const';
import ReactQuill from 'react-quill';
import moment from 'moment';
import FileLabel from '../../../components/FileLabel/FileLabel';
import { AddFileModal } from '../../../components/AddFileModal';
import AwardsBlock from '../../../components/AwardsBlock';
import { FaFilePdf } from 'react-icons/fa';



export default function NewCertificationInfo({
  formData,
  specializations,
  criterions,
  loading,
  activeMode,
  setActiveMode,
  clearRequest,
  resetForm
}) {
  
  
  const {values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setTouched, isValid} = formData

  const onAddFile = (fileObject) => {
    const { entity_type: name, file } = fileObject
    const _file = file[0]
    const newItem = {...fileObject, file: _file}
    const prevValue = values[name] || []
    const filesList = [...prevValue, newItem]
    setFieldValue(name, filesList)
  }
  
  const onDelete = (name, index) => () => {
    const filesList = [...values[name]]
    filesList.splice(index, 1)
    setFieldValue(name, filesList)
  }

  const cancelClick = () => {
    setActiveMode(prev => !prev)
    if (!activeMode) return;
    clearRequest()
    resetForm()
  }

  const cancelButtonLabel = activeMode ? (criterions ? 'Сохранить черновик и выйти' : 'Отмена') : 'Подать заявление'

  const renderAwardsRow = ({name, date}) => (
    <tr>
      <td>{name}</td>
      <td>{moment(date).format('DD.MM.YYYY')}</td>
    </tr>
  )
  
  const renderPedQualRow = ({name, year, hours}) => (
    <tr>
      <td>{name}</td>
      <td>{moment(year).year()}</td>
      <td style={{textAlign: 'center'}}>{hours}</td>
    </tr>
  )

  const simpleCertToggleClick = () => {
    setFieldValue('simple_cert', !values.simple_cert)
    setFieldValue('cert_request_award', [])
  }

  const getInstructionHandler = () => {
    const link = document.createElement('a')
    link.setAttribute('href', `${BACKEND_DOMAIN || ''}/help/Педагогический%20работник.pdf`)
    link.setAttribute('target', '_blank')
    link.setAttribute('rel', 'noopener noreferrer')
    link.click()
  }

  return (
    <div>
      {activeMode &&
        <>
          <h3 className={styles.formBlockTitle}>Подача заявления (данные):</h3>
          <Form>
            <Row className={styles.simpleCertRow}>
              <Form.Check
                label='аттестация педагогических работников, имеющих государственные награды, почетные звания, ведомственные знаки отличия, награды полученные за достижения в педагогической деятельности, награды конкурсов профессионального мастерства'
                checked={values.simple_cert}
                onChange={simpleCertToggleClick}
              />
            </Row>
            <Row className='mb-3'>
              
              <Form.Group as={Col} md={3}>
                <Form.Label className={styles.formLabels}>Год:</Form.Label>
                <Form.Select
                  name='cert_year'
                  value={values.cert_year}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.cert_year && errors.cert_year}
                  disabled={criterions}
                >
                  <option value=''>Выбрать</option>
                  {getYearsOptions(5)}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.cert_year}</Form.Control.Feedback>
              </Form.Group>
              
              <Form.Group as={Col} md={6}>
                <Form.Label className={styles.formLabels}>Должность:</Form.Label>
                <Form.Select
                  name='position'
                  value={values.position}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.position && errors.position}
                  disabled={criterions}
                >
                  <option value=''>Выбрать</option>
                  {getOptions(specializations)}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.position}</Form.Control.Feedback>
              </Form.Group>
              
              <Form.Group as={Col} md={3}>
                <Form.Label className={styles.formLabels}>Категория:</Form.Label>
                <Form.Select
                  name='category'
                  value={values.category}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.category && errors.category}
                  disabled={criterions}
                >
                  <option value=''>Выбрать</option>
                  {getOptions(categories)}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.category}</Form.Control.Feedback>
              </Form.Group>

            </Row>
            <Row className='mb-3'>
              
              <Form.Group as={Col} md={7}>
                <Form.Label className={styles.formLabels}>Основание для аттестации:</Form.Label>
                <ReactQuill
                  className={styles.quill}
                  value={values.cert_reason}
                  name='cert_reason'
                  onChange={(val) => setFieldValue('cert_reason', val)}
                  readOnly={criterions}
                />
              </Form.Group>
              
              <Form.Group as={Col} md={5}>
                <Form.Label className={styles.formLabels}>Занимаемая должность:</Form.Label>
                <Form.Control
                  name='current_position'
                  value={values.current_position}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.current_position && errors.current_position}
                  disabled={criterions}
                />
                <Form.Control.Feedback type="invalid">{errors.current_position}</Form.Control.Feedback>
              
              </Form.Group>
            </Row>
            <Row>
              
              <Form.Group className={styles.yearsInput} as={Col} md={4}>
                <Form.Label className={styles.formLabels}>Стаж в данной должности:</Form.Label>
                <Form.Control
                  name='position_exp'
                  value={values.position_exp}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.position_exp && errors.position_exp}
                  disabled={criterions}
                  type='number'
                  />
                <Form.Control.Feedback type="invalid">{errors.position_exp}</Form.Control.Feedback>
              </Form.Group>
              
              <Form.Group className={styles.yearsInput} as={Col} md={4}>
                <Form.Label className={styles.formLabels}>Стаж педагогической работы:</Form.Label>
                <Form.Control
                  name='edu_exp'
                  value={values.edu_exp}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.edu_exp && errors.edu_exp}
                  disabled={criterions}
                  type='number'
                  />
                <Form.Control.Feedback type="invalid">{errors.edu_exp}</Form.Control.Feedback>
              </Form.Group>
              
              <Form.Group className={styles.yearsInput} as={Col} md={4}>
                <Form.Label className={styles.formLabels}>Стаж в данном учреждении:</Form.Label>
                <Form.Control
                  name='org_exp'
                  value={values.org_exp}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.org_exp && errors.org_exp}
                  disabled={criterions}
                  type='number'
                />
                <Form.Control.Feedback type="invalid">{errors.org_exp}</Form.Control.Feedback>
              </Form.Group>

            </Row>
            <p className={styles.yearsDescription}>полных лет на дату заполнения</p>
            
            <div className={styles.filesBlock}>
              <div className={styles.requestFiles}>
                <Form.Label className={styles.formLabels}>Скан копия заявления:</Form.Label>
                <div>
                  {values.cert_request_scan.map((file, index) => {
                    return (
                      <FileLabel
                        fileLink={URL.createObjectURL(file.file)}
                        fileName={file.user_filename}
                        imageMode={file.file.type.includes('image')}
                        pdfMode={file.file.type.includes('pdf')}
                        onDelete={!criterions && onDelete('cert_request_scan', index)}
                      />
                    )
                  })}
                  <AddFileModal
                    entity_type='cert_request_scan'
                    onAddFile={onAddFile}
                    disabled={criterions}
                  />
                </div>
              </div>
              
              <div className={styles.teacherInfoFiles}>
                <Form.Label className={styles.formLabels}>Общие сведения о педагоге:</Form.Label>
                <div>
                  {values.cert_request_common.map((file, index) => {
                    return (
                      <FileLabel
                        fileLink={URL.createObjectURL(file.file)}
                        fileName={file.user_filename}
                        imageMode={file.file.type.includes('image')}
                        pdfMode={file.file.type.includes('pdf')}
                        onDelete={!criterions && onDelete('cert_request_common', index)}
                      />
                    )
                  })}
                  <AddFileModal
                    entity_type='cert_request_common'
                    onAddFile={onAddFile}
                    disabled={criterions}
                  />
                </div>
              </div>
            </div>

            {values.simple_cert &&
              <div>
              <Form.Label className={styles.formLabels}>Государственные награды, почетные звания, ведомственные знаки отличия, награды полученные за достижения в педагогической деятельности, награды конкурсов профессионального мастерства:</Form.Label>
              <AwardsBlock
                onAddFile={onAddFile}
                files={values.cert_request_award}
                onDeleteFile={onDelete}
                disabled={criterions}
              />
            </div>}

            <Row className={styles.moreInfoBlock}>
              <h5>Доп. информация:</h5>
              
              <Form.Group>
                <Form.Label className={styles.formLabels}>Ученая степень:</Form.Label>
                <div>{values.academic_degree || 'не указана'}</div>
              </Form.Group>
              
              <Form.Group>
                <Form.Label className={styles.formLabels}>Ученое звание:</Form.Label>
                <div>{values.academic_title || 'не указано'}</div>
              </Form.Group>
              
              <Form.Group>
                <Form.Label className={styles.formLabels}>Сведения о повышении квалификации:</Form.Label>
                <Table>
                  <thead>
                    <tr>
                      <th>Название</th>
                      <th>Год</th>
                      <th style={{width: '155px'}}>Количество часов</th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.ped_qual?.length
                      ? values.ped_qual.map(renderPedQualRow)
                      : <tr className={styles.noDataRow}>
                          <td colSpan={3}>Данные отсутствуют</td>
                        </tr>
                    }
                  </tbody>
                </Table>
              </Form.Group>

            </Row>

          </Form>
        </>
      }
      <div className={styles.criterionButtons}>
        {activeMode &&
          <Button
            type='submit'
            label='Далее'
            onClick={handleSubmit}
            disabled={!isValid || criterions}
            submitting={loading}
          />
        }
        <Button
          label={cancelButtonLabel}
          onClick={cancelClick}
          type='button'
        />
        <Button onClick={getInstructionHandler} style={{backgroundColor: 'red'}}>
          <FaFilePdf/>
          РУКОВОДСТВО ПОЛЬЗОВАТЕЛЯ
        </Button>
      </div>
    </div>
  )
}