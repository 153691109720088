import { useFormik } from 'formik'
import React, { useState } from 'react'
import { awardsFilesFormSchema, requestFilesFormSchema } from '../../assets/validation';
import styles from './styles.module.scss'
import { IoMdAdd } from "react-icons/io";
import Modal from 'react-responsive-modal';
import { Form } from 'react-bootstrap';
import FileInput from '../FileInput/FileInput';
import Button from '../Button';
import { getYearsOptions } from '../../utils/getOptions';
import moment from 'moment';


export const AddFileModal = ({entity_type, onAddFile, awardsMode, disabled}) => {
  
  const initialValues = {
    user_filename: '',
    entity_type,
    file: null
  }

  const [openModal, setOpenModal] = useState(false)

  const closeModal = () => {
    setOpenModal(false)
    resetForm()
  }

  const {
    values,
    errors,
    touched,
    isValid,
    setTouched,
    setFieldValue,
    resetForm,
    handleChange,
    handleBlur,
    handleSubmit
  } = useFormik({
    initialValues: awardsMode ? initialValues : {...initialValues, year: null},
    validationSchema: awardsMode ? awardsFilesFormSchema : requestFilesFormSchema,
    onSubmit: file => {
      onAddFile(file)
      resetForm()
      closeModal()
    }
  })
  
  const handleSetTouched = (name) => {
    setTouched({...touched, [name]: true})
  }

  const handleSetFile = (e) => {
    const filesList = e.target?.files
    const file = filesList?.length ? [ filesList[0] ] : null
    setFieldValue('file', file)
  }

  const buttonStyles = awardsMode && { width: '50px', height: '50px' }

  return (
    <>
      {!disabled &&
        <div
          onClick={() => setOpenModal(true)}
          className={styles.fileModalButton}
          style={buttonStyles}
        >
          <IoMdAdd size={30} color='#ced4da'/>
        </div>
      }
      <Modal
        open={openModal}
        onClose={closeModal}
        showCloseIcon={false}
        animationDuration={1}
        classNames={{
          modal: styles.filesModal
        }}
      >
        {awardsMode &&
          <Form.Group style={{marginBottom: '1rem'}}>
            <Form.Label className={styles.formLabels}>Год:</Form.Label>
            <Form.Select
              name='year'
              value={values.year}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.year && errors.year}
            >
                <option value=''>Не выбран</option>
                {getYearsOptions(moment().year() - 1999, 'reverse')}
            </Form.Select>
            <Form.Control.Feedback type="invalid">{errors.year}</Form.Control.Feedback>
          </Form.Group>
        }

        <Form.Group>
          <Form.Label className={styles.formLabels}>Название документа:</Form.Label>
          <Form.Control
            name='user_filename'
            value={values.user_filename}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched.user_filename && errors.user_filename}
          />
          <Form.Control.Feedback type="invalid">{errors.user_filename}</Form.Control.Feedback>
        </Form.Group>

        <div className={styles.fileItem}>
          <span>Файл:</span>
          <FileInput
            height='300px'
            name='file'
            files={values.file}
            onChange={handleSetFile}
            onBlur={handleBlur}
            isInvalid={touched.file && errors.file}
            onDelete={handleSetFile}
            setTouched={handleSetTouched}
          />
        </div>

        <div className={styles.buttonsBlock}>
          <Button onClick={handleSubmit}>Добавить</Button>
        </div>
      </Modal>
    </>
  )
}
